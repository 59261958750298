const LogoSVG = (props) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      width={props.width}
      viewBox="0 0 502 270"
    >
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M292.000000,271.000000 
   C194.666687,271.000000 97.833366,271.000000 1.000039,271.000000 
   C1.000026,181.000031 1.000026,91.000069 1.000013,1.000075 
   C168.333267,1.000050 335.666534,1.000050 502.999878,1.000025 
   C502.999908,90.999916 502.999908,180.999832 502.999939,270.999878 
   C432.833344,271.000000 362.666656,271.000000 292.000000,271.000000 
M273.211670,189.530045 
   C273.594330,191.780136 273.976959,194.030228 274.506592,197.018158 
   C274.619507,198.015350 274.732422,199.012543 274.379150,200.368057 
   C274.805573,201.309921 275.231964,202.251785 275.832733,203.925735 
   C276.097626,205.148514 276.362518,206.371307 276.330811,208.276932 
   C277.112701,209.915176 277.894592,211.553436 278.945251,213.866318 
   C283.604767,225.970551 291.640564,235.421005 302.049225,243.753006 
   C302.785004,244.069962 303.520813,244.386917 304.878326,244.992249 
   C329.752411,260.672089 364.640839,259.349182 387.596649,241.798431 
   C406.751801,227.153427 414.136230,207.026382 414.173584,183.682938 
   C414.257843,131.054367 414.201508,78.425552 414.202148,25.796837 
   C414.202271,16.145041 414.201782,16.145039 404.796783,16.174215 
   C397.965424,16.195408 397.797882,16.348330 397.796844,23.169912 
   C397.791290,59.810158 397.793518,96.450409 397.793091,133.090652 
   C397.793060,134.735611 397.793091,136.380585 397.793091,138.025543 
   C373.058105,102.308769 302.141846,102.974358 280.004211,149.998215 
   C279.798645,150.768997 279.593048,151.539764 279.248199,152.857101 
   C279.131531,153.214386 279.014862,153.571655 278.362885,154.003632 
   C278.239716,154.669022 278.116547,155.334396 277.851349,156.591415 
   C277.732910,156.979218 277.614441,157.367035 276.914276,158.048004 
   C276.807312,158.833954 276.700348,159.619904 276.396759,161.128891 
   C276.149963,162.354614 275.903168,163.580338 275.070831,164.926834 
   C274.994690,165.949142 274.918518,166.971436 274.779816,168.711548 
   C274.602661,170.138992 274.425476,171.566437 273.674194,173.478348 
   C273.684631,175.646790 273.695068,177.815231 273.707825,180.736435 
   C273.715759,183.497650 273.723663,186.258865 273.211670,189.530045 
M139.719666,202.500076 
   C139.933945,189.513107 139.472076,176.471649 140.513504,163.551361 
   C142.284378,141.580978 156.097565,128.945023 178.107681,127.897285 
   C196.916611,127.001930 210.938736,133.795181 216.801483,148.442963 
   C219.881226,156.137573 221.560974,164.833481 221.804184,173.137817 
   C222.472061,195.944077 222.031082,218.782410 222.060028,241.608002 
   C222.073196,251.991241 220.581726,251.732285 232.396851,251.819763 
   C236.589508,251.850830 238.421478,250.557571 238.394958,246.098862 
   C238.249268,221.607971 238.440079,197.114777 238.241211,172.624603 
   C238.129990,158.927643 235.264008,145.969345 227.342728,134.321503 
   C207.411682,105.013908 150.989441,106.107292 133.504639,136.294434 
   C133.050476,137.078506 132.313110,137.698563 131.582016,138.539886 
   C130.903351,137.819870 130.358398,137.408539 130.027771,136.866470 
   C122.361755,124.297684 111.074532,116.884270 96.751434,114.421753 
   C78.820938,111.339035 61.374626,112.513618 46.239326,123.945107 
   C30.888456,135.539429 25.240728,151.973190 25.062666,170.530716 
   C24.835680,194.187286 24.905176,217.847961 25.023342,241.505997 
   C25.075075,251.863556 22.386274,251.703354 35.550285,251.823166 
   C39.933197,251.863068 41.414131,250.161713 41.368759,245.901505 
   C41.216175,231.574631 41.236565,217.244598 41.334290,202.916763 
   C41.422890,189.927124 41.148392,176.901337 42.027523,163.958984 
   C43.214596,146.483215 52.877705,133.639374 68.092102,129.932739 
   C75.337784,128.167480 83.306625,128.114838 90.789558,128.865250 
   C102.853622,130.075073 111.625214,137.043945 117.378670,147.681808 
   C122.016609,156.257156 123.458717,165.596817 123.484825,175.191406 
   C123.545128,197.350464 123.507828,219.509766 123.508873,241.668976 
   C123.509361,251.943771 123.508667,251.788727 133.931702,251.830185 
   C138.244705,251.847336 139.843277,250.330658 139.789093,245.984955 
   C139.612549,231.825256 139.721405,217.661987 139.719666,202.500076 
M480.309998,216.500412 
   C480.310028,185.850204 480.313721,155.199997 480.307343,124.549789 
   C480.305817,117.175560 480.214264,117.101410 472.646667,117.099937 
   C471.980347,117.099808 471.314056,117.099052 470.647736,117.101593 
   C464.066681,117.126740 463.907959,117.272400 463.907196,123.848709 
   C463.902679,162.994385 463.879669,202.140091 463.921600,241.285721 
   C463.932861,251.780640 462.025238,251.744263 474.448334,251.821716 
   C478.731750,251.848404 480.453400,250.333862 480.367004,245.984009 
   C480.178528,236.492859 480.309326,226.995346 480.309998,216.500412 
M459.992981,82.264053 
   C460.877655,83.247780 461.659149,84.355202 462.664764,85.193924 
   C467.118500,88.908447 474.044495,89.409508 479.006592,86.469002 
   C484.060669,83.473984 487.244476,77.318642 486.353516,72.264938 
   C485.242737,65.964355 480.831299,61.207981 474.697449,60.155048 
   C469.222260,59.215195 464.422791,60.852905 460.923340,65.232727 
   C456.867065,70.309517 456.947510,75.906113 459.992981,82.264053 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M139.720047,203.000092 
   C139.721405,217.661987 139.612549,231.825256 139.789093,245.984955 
   C139.843277,250.330658 138.244705,251.847336 133.931702,251.830185 
   C123.508667,251.788727 123.509361,251.943771 123.508873,241.668976 
   C123.507828,219.509766 123.545128,197.350464 123.484825,175.191406 
   C123.458717,165.596817 122.016609,156.257156 117.378670,147.681808 
   C111.625214,137.043945 102.853622,130.075073 90.789558,128.865250 
   C83.306625,128.114838 75.337784,128.167480 68.092102,129.932739 
   C52.877705,133.639374 43.214596,146.483215 42.027523,163.958984 
   C41.148392,176.901337 41.422890,189.927124 41.334290,202.916763 
   C41.236565,217.244598 41.216175,231.574631 41.368759,245.901505 
   C41.414131,250.161713 39.933197,251.863068 35.550285,251.823166 
   C22.386274,251.703354 25.075075,251.863556 25.023342,241.505997 
   C24.905176,217.847961 24.835680,194.187286 25.062666,170.530716 
   C25.240728,151.973190 30.888456,135.539429 46.239326,123.945107 
   C61.374626,112.513618 78.820938,111.339035 96.751434,114.421753 
   C111.074532,116.884270 122.361755,124.297684 130.027771,136.866470 
   C130.358398,137.408539 130.903351,137.819870 131.582016,138.539886 
   C132.313110,137.698563 133.050476,137.078506 133.504639,136.294434 
   C150.989441,106.107292 207.411682,105.013908 227.342728,134.321503 
   C235.264008,145.969345 238.129990,158.927643 238.241211,172.624603 
   C238.440079,197.114777 238.249268,221.607971 238.394958,246.098862 
   C238.421478,250.557571 236.589508,251.850830 232.396851,251.819763 
   C220.581726,251.732285 222.073196,251.991241 222.060028,241.608002 
   C222.031082,218.782410 222.472061,195.944077 221.804184,173.137817 
   C221.560974,164.833481 219.881226,156.137573 216.801483,148.442963 
   C210.938736,133.795181 196.916611,127.001930 178.107681,127.897285 
   C156.097565,128.945023 142.284378,141.580978 140.513504,163.551361 
   C139.472076,176.471649 139.933945,189.513107 139.720047,203.000092 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M280.621857,149.790970 
   C302.141846,102.974358 373.058105,102.308769 397.793091,138.025543 
   C397.793091,136.380585 397.793060,134.735611 397.793091,133.090652 
   C397.793518,96.450409 397.791290,59.810158 397.796844,23.169912 
   C397.797882,16.348330 397.965424,16.195408 404.796783,16.174215 
   C414.201782,16.145039 414.202271,16.145041 414.202148,25.796837 
   C414.201508,78.425552 414.257843,131.054367 414.173584,183.682938 
   C414.136230,207.026382 406.751801,227.153427 387.596649,241.798431 
   C364.640839,259.349182 329.752411,260.672089 304.478027,244.544159 
   C303.360382,243.765213 302.643036,243.434372 301.925659,243.103516 
   C291.640564,235.421005 283.604767,225.970551 278.889404,213.182510 
   C278.098145,210.863831 277.362762,209.228958 276.627380,207.594086 
   C276.362518,206.371307 276.097626,205.148514 275.883301,203.219238 
   C275.571014,201.678406 275.208160,200.844070 274.845337,200.009735 
   C274.732422,199.012543 274.619507,198.015350 274.601837,196.241364 
   C274.375244,193.316406 274.053436,191.168243 273.731598,189.020081 
   C273.723663,186.258865 273.715759,183.497650 273.999481,180.135147 
   C274.276855,177.353867 274.262604,175.173874 274.248322,172.993881 
   C274.425476,171.566437 274.602661,170.138992 275.111755,168.149338 
   C275.514557,166.660095 275.585449,165.733078 275.656372,164.806046 
   C275.903168,163.580338 276.149963,162.354614 276.790741,160.639618 
   C277.288483,159.351837 277.392242,158.553345 277.495972,157.754852 
   C277.614441,157.367035 277.732910,156.979218 278.220154,156.167709 
   C278.692078,155.138977 278.795135,154.533951 278.898193,153.928925 
   C279.014862,153.571655 279.131531,153.214386 279.622955,152.483017 
   C280.205750,151.336273 280.413788,150.563629 280.621857,149.790970 
M335.017517,128.037079 
   C314.711975,130.773788 300.038452,141.242874 293.890411,160.946075 
   C291.030518,170.111481 290.083069,180.285553 290.371582,189.939545 
   C291.068115,213.243774 304.878052,231.535370 325.381561,237.829041 
   C336.419525,241.217209 347.716888,241.224136 358.971924,238.709976 
   C372.725128,235.637802 383.148285,227.898346 390.111633,215.691360 
   C398.453461,201.067780 399.408142,185.305984 396.318939,169.181198 
   C392.481384,149.149918 381.208557,135.111237 361.152313,129.954514 
   C353.101593,127.884552 344.355194,128.520325 335.017517,128.037079 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M480.309814,217.000427 
   C480.309326,226.995346 480.178528,236.492859 480.367004,245.984009 
   C480.453400,250.333862 478.731750,251.848404 474.448334,251.821716 
   C462.025238,251.744263 463.932861,251.780640 463.921600,241.285721 
   C463.879669,202.140091 463.902679,162.994385 463.907196,123.848709 
   C463.907959,117.272400 464.066681,117.126740 470.647736,117.101593 
   C471.314056,117.099052 471.980347,117.099808 472.646667,117.099937 
   C480.214264,117.101410 480.305817,117.175560 480.307343,124.549789 
   C480.313721,155.199997 480.310028,185.850204 480.309814,217.000427 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M459.782349,81.944611 
   C456.947510,75.906113 456.867065,70.309517 460.923340,65.232727 
   C464.422791,60.852905 469.222260,59.215195 474.697449,60.155048 
   C480.831299,61.207981 485.242737,65.964355 486.353516,72.264938 
   C487.244476,77.318642 484.060669,83.473984 479.006592,86.469002 
   C474.044495,89.409508 467.118500,88.908447 462.664764,85.193924 
   C461.659149,84.355202 460.877655,83.247780 459.782349,81.944611 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M273.961243,173.236115 
   C274.262604,175.173874 274.276855,177.353867 273.998291,179.758759 
   C273.695068,177.815231 273.684631,175.646790 273.961243,173.236115 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M273.471619,189.275055 
   C274.053436,191.168243 274.375244,193.316406 274.528320,195.872437 
   C273.976959,194.030228 273.594330,191.780136 273.471619,189.275055 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M276.479095,207.935516 
   C277.362762,209.228958 278.098145,210.863831 278.755005,212.845200 
   C277.894592,211.553436 277.112701,209.915176 276.479095,207.935516 
z"
      />
      <path
        fill="#9C9C9C"
        opacity="1.000000"
        stroke="none"
        d="
M275.363586,164.866440 
   C275.585449,165.733078 275.514557,166.660095 275.143005,167.790436 
   C274.918518,166.971436 274.994690,165.949142 275.363586,164.866440 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M274.612244,200.188904 
   C275.208160,200.844070 275.571014,201.678406 275.796112,202.853195 
   C275.231964,202.251785 274.805573,201.309921 274.612244,200.188904 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M280.313049,149.894592 
   C280.413788,150.563629 280.205750,151.336273 279.692566,152.209747 
   C279.593048,151.539764 279.798645,150.768997 280.313049,149.894592 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M278.630554,153.966278 
   C278.795135,154.533951 278.692078,155.138977 278.291199,155.871887 
   C278.116547,155.334396 278.239716,154.669022 278.630554,153.966278 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M277.205139,157.901428 
   C277.392242,158.553345 277.288483,159.351837 276.889038,160.278091 
   C276.700348,159.619904 276.807312,158.833954 277.205139,157.901428 
z"
      />
      <path
        fill={props.color}
        opacity="1.000000"
        stroke="none"
        d="
M301.987427,243.428253 
   C302.643036,243.434372 303.360382,243.765213 304.167175,244.399963 
   C303.520813,244.386917 302.785004,244.069962 301.987427,243.428253 
z"
      />
      <path
        fill="transparent"
        opacity="1.000000"
        stroke="none"
        d="
M335.471466,127.980827 
   C344.355194,128.520325 353.101593,127.884552 361.152313,129.954514 
   C381.208557,135.111237 392.481384,149.149918 396.318939,169.181198 
   C399.408142,185.305984 398.453461,201.067780 390.111633,215.691360 
   C383.148285,227.898346 372.725128,235.637802 358.971924,238.709976 
   C347.716888,241.224136 336.419525,241.217209 325.381561,237.829041 
   C304.878052,231.535370 291.068115,213.243774 290.371582,189.939545 
   C290.083069,180.285553 291.030518,170.111481 293.890411,160.946075 
   C300.038452,141.242874 314.711975,130.773788 335.471466,127.980827 
z"
      />
    </svg>
  );
};

export default LogoSVG;
